import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 2-2-2-2-2 to a 2 rep max`}</p>
    <p>{`Assistance: Stability Ball Leg Curl 8-8-8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`1000m row for time.`}</p>
    <p>{`rest 2:00 then,`}</p>
    <p>{`Death by Back Squat (Men=bodyweight, Women=3/4th’s bodyweight)`}</p>
    <p>{`(1 squat the first minute, 2 the second etc).`}</p>
    <p><em parentName="p">{`*`}{`compare to 11/16/16`}</em></p>
    <p><strong parentName="p">{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</strong></p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20-Back Rack Barbell Lunges (135/95)`}</p>
    <p>{`10-Burpees.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      